/* Import Montserrat Font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

/* Step 1: Global Variables */
:root {
  --primary-color: #2c6449; /* Primary brand color */
  --accent-color-1: #2c6452; /* First accent color */
  --accent-color-2: #2c3464; /* Second accent color */
  --accent-color-3: #362c64; /* Third accent color */
  --neutral-accent: #5a642c; /* Neutral accent color */
  --text-color: #2c3e50; /* Default text color */
  --background-light: #f9f9f9; /* Light background for contrast */
  --border-light: #ddd; /* Light border color */
}

/* Set font size for headers (h1 to h6) */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 16px;
}

/* Set font size for all other text */
body,
p,
li,
span,
label,
button,
input {
  font-size: 14px;
}

.text-success {
  color: #2c6449 !important; /* Overrides Bootstrap globally */
}

/* Global Styles */
body {
  font-family: "Montserrat", sans-serif;
  color: var(--text-color) !important;
  background-color: var(--background-light); /* Apply background color */
  margin: 0; /* Remove any default margin */
  padding: 0;
}

/* OR apply to all elements */
* {
  font-family: "Montserrat", sans-serif;
}

/* NOTIFICATION */
.notification-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: var(--primary-color); /* Primary color */
  padding: 20px 30px;
  border-radius: 8px;
  color: var(--text-color); /* Text color */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
  text-align: center;
}

.notification-content strong {
  font-size: 1rem;
  color: var(--text-color); /* Text color */
}

.notification-content p {
  margin: 10px 0 0 0;
  font-size: 0.9rem;
  color: var(--text-color); /* Text color */
}

.notification-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.25rem;
  color: var(--text-color); /* Text color */
  cursor: pointer;
}

.notification-close:hover {
  color: var(--border-light); /* Light border */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* SPINNER */
/* Container for the spinner */
.loading-spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9); /* Light background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Rotating circle */
.spinner-circle {
  width: 120px;
  height: 120px;
  border: 4px solid var(--border-light); /* Light border */
  border-top: 2px solid var(--primary-color); /* Primary color */
  border-radius: 50%;
  animation: spin 1.5s linear infinite; /* Circle rotation animation */
  position: absolute; /* Ensure it does not affect the content below */
}

/* Stationary content inside the spinner */
.spinner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Ensures it stays centered within the container */
  z-index: 2; /* Stays above the rotating circle */
}

.spinner-logo {
  width: 80px;
  height: 80px;
}

.spinner-text {
  font-size: 14px;
  font-weight: bold;
  color: var(--accent-color-3); /* Third accent color */
  margin-top: 10px;
}

/* Keyframes for the rotating circle */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-placeholder::placeholder {
  font-size: 0.8rem; /* Adjust size as needed */
}

.table-bordered {
  border: 1px solid #ddd !important;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd !important;
}

.simple-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  z-index: 2;
  cursor: pointer;
}

.simple-arrow.left {
  left: -40px;
}

.simple-arrow.right {
  right: -40px;
}
